import React from "react";
import Header from "../components/header/header.js";
import ReadMore from "../components/readMore/readMore.js";
import AdminPanel from "../assets/img/adminPanel.png";

import "../assets/styles/pages/_hospitality.scss";

const PageProps = {
  title: "Hospitality",
  metaDescription: "",
};

const Hospitality = () => {
  return (
    <>
      <Header {...PageProps} />
      <div className="container hospitality">
        <div className="columns">
          <div className="column">
            <p>
              <span className="black bold">
                Con Eucleia puoi contattare i clienti e sondare il loro grado di
                soddisfazione su servizi ricevuti, prodotti acquistati o brand
                comunicato; sfruttando al massimo il rapporto di contatto
                privilegiato creato attraverso il WiFi di Connect@You e/o con
                l’integrazione al booking engine di Book@Me.
              </span>
              <div className="section" />
              Offri al tuo cliente un servizio trasversale di côncierge che lo
              accompagnerà dal momento della prenotazione sino al check-out.
              Attira i clienti e fidelizzali sfruttando il WiFi e i suoi
              strumenti di promozione e comunicazione.
              <br />
              <br />
              Un sistema ideale per gestire le insoddisfazioni, con lo scopo di
              portare l’utente al rilascio di una recensione positiva sulla
              pagina web dedicata al brand.
            </p>
          </div>
          <div className="column is-offset-1">
            <img
              src={AdminPanel}
              alt="Admin Panel"
            />
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column is-6">
            <h2>Connect@You</h2>
            <br />
            <p>
              Connect@You è un moderno servizio di hotspot grazie al quale
              potrai offrire ai tuoi clienti una connessione WiFi gratuita,
              facile e sicura, offrendogli la possibilità di essere in rete con
              pochi click, mettendoti al riparo dalla normativa vigente.
              Connect@You è infatti compliant al nuovo GDPR Regolamento (UE)
              2016/679.
            </p>
            <br />
            <br />
            <ReadMore to="https://connectatyou.it/" text="Scopri di più" color="white" externalLink={true}/>
          </div>
          <div className="column is-3 is-offset-1">
            <div className="container">
              <img
                src="https://static.acmeproduzioni.it/connectatyou/logo.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="section" />
        <div className="columns invert">
          <div className="column is-3 is-offset-1">
            <div className="container">
              <img
                src="https://static.acmeproduzioni.it/bookatme/logo.svg"
                alt=""
              />
            </div>
          </div>
          <div className="column is-offset-2 is-6">
            <h2>Book@Me</h2>
            <br />
            <p>
              Book@Me è un booking engine con channel manager integrato in cloud
              che ti consente di ricevere prenotazioni dirette senza
              commissioni, abbattendo costi per il tuo business, e di gestire
              tutti i tuoi canali web grazie al channel manager, evitando così
              il rischio di overbooking. Grazie al checkIN online facilita
              l’operatività del receptionist e consente al tuo cliente di
              registrarsi ovunque esso si trovi!
            </p>
            <br />
            <br />
            <ReadMore to="https://www.bookatme.cloud/" text="Scopri di più" color="white" externalLink={true}/>
          </div>
        </div>
        <div className="section" />
      </div>
    </>
  );
};

export default Hospitality;
